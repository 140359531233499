<script>
import moment from "moment";

export default {
  name: "ViewText",
  data() {
    return {
      showModal: false,
      inquiry: null
    }
  },
  computed: {
    canShowModal() {
      return this.inquiry !== null;
    }
  },
  methods: {
    openModal(inquiry) {
      if (!inquiry) return alert('No inquiry found');
      this.inquiry = inquiry;
      this.showModal = true;
    },
    formatDateTime(value) {
      return moment(value).format('LLL');
    },
  },
}
</script>

<template>
  <b-modal v-model="showModal" centered hide-footer size="lg">
    <template v-slot:title v-if="canShowModal">
      <h4 class="mb-0">
                  <span v-if="inquiry.status === 'success'"
                        class="badge rounded-pill border border-success text-success">Accepted</span>
        <span v-else-if="inquiry.status === 'fail'"
              class="badge rounded-pill border border-danger text-danger">Rejected</span>
        <span v-else-if="inquiry.status === 'pending'" class="badge rounded-pill border border-warning text-warning">Pending</span>
        <span v-else-if="inquiry.status === 'quoted'"
              class="badge rounded-pill border border-secondary text-secondary">Quoted</span>
      </h4>
    </template>
    <div v-if="canShowModal" style="font-size: 14px" v-html="inquiry.text" class="mb-4"></div>
    <div class="d-flex justify-content-between align-items-center gap-3" v-if="canShowModal">
      <div class="d-flex gap-3">
        <div v-if="inquiry.sender" @mouseover="inquiry.sender.is_hovered = true"
             @mouseleave="inquiry.sender.is_hovered = false"
             :class="{'bg-light': inquiry.sender.is_hovered}"
             v-b-tooltip.hover title="Sender"
             class="d-flex align-items-center border p-2 rounded-3">
          <div class="avatar-xs flex-shrink-0 me-3">
            <div class="avatar-title bg-soft-success text-success rounded-circle">
              {{ inquiry.sender.username[0] }}
            </div>
          </div>
          <div class="flex-grow-1">
            <h5 class="fs-13 mb-0">
              <a class="text-body d-block"> {{ inquiry.sender.username }} </a>
            </h5>
            <small class="text-muted">Sender</small>
          </div>
        </div>
        <div v-if="inquiry.sales_manager" @mouseover="inquiry.sales_manager.is_hovered = true"
             @mouseleave="inquiry.sales_manager.is_hovered = false"
             :class="{'bg-light': inquiry.sales_manager.is_hovered}"
             v-b-tooltip.hover title="Sales Manager"
             class="d-flex align-items-center border p-2 rounded-3">
          <div class="avatar-xs flex-shrink-0 me-3">
            <div class="avatar-title bg-soft-success text-success rounded-circle">
              {{ inquiry.sales_manager.username[0] }}
            </div>
          </div>
          <div class="flex-grow-1">
            <h4 class="fs-13 mb-0">
              <a class="text-body d-block"> {{ inquiry.sales_manager.username }} </a>
            </h4>
            <small class="text-muted">Sales manager</small>
          </div>
        </div>
      </div>
      <div class="text-end">
        <div class="d-flex flex-column justify-content-start align-items-start gap-1">
          <span v-b-tooltip.hover title="Date created">
            <i class="mdi mdi-clock text-muted"></i>
            {{ formatDateTime(inquiry.created) }}</span>
          <span v-b-tooltip.hover title="Date modified">
            <i class="mdi mdi-pencil text-muted"></i>
            {{ formatDateTime(inquiry.modified) }}</span>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<style scoped>

</style>